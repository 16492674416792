<div class="container">
  <mat-icon
      aria-hidden="false"
      aria-label="For change to notes"
      [svgIcon]="'bubbles'"
      class="img"
    ></mat-icon>
  
    <p class="title"  *ngIf="data.title && data.name">
      ¿{{ data.title }} {{ data.name }}?
    </p>

  <p class="title" *ngIf="data.title && !data.name"
    ><strong mat-dialog-title>¿{{ data.title }}?</strong></p
  >

  <div mat-dialog-content *ngIf="data.text" class="modalText">
    <p class="text">{{ data.text }}</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button class="cancel" (click)="cancel()" cdkFocusInitial>
      Cancelar
    </button>
    <button
      mat-button
      class="confirm"
      [mat-dialog-close]="true"
      cdkFocusInitial
    >
      Aceptar
    </button>
  </div>
</div>
