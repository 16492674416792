import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, Auth, UserCredential } from 'firebase/auth';
import { getFirestore, Firestore } from 'firebase/firestore';
import { firebaseConfig, userCredentials } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  public auth: Auth;
  public db: Firestore;

  constructor() {
    const app = initializeApp(firebaseConfig);
    this.auth = getAuth(app);
    this.db = getFirestore(app);
    this.signIn();
  }

  signIn(): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, userCredentials.email, userCredentials.password);
  }
}
