import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { MaterialModule } from '../material.module';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ChatRoutingModule } from './chat-routing.module';

import { ConversationComponent } from './components/conversation/conversation.component';
import { ConversationListComponent } from './components/conversation-list/conversation-list.component';
import { MessageComponent } from './components/message/message.component';
import { MessageInputComponent } from './components/message-input/message-input.component';
import { ConversationHeaderComponent } from './components/conversation-header/conversation-header.component';
import { ChatComponent } from './pages/chat/chat.component';
import { NotificationComponent } from './components/notification/notification.component';
import { ContactSupportComponent } from './components/contact-support/contact-support.component';
import { FilterPipe } from './pipes/filter.pipe';
import { ActionConfirmComponent } from './components/modals/action-confirm/action-confirm.component';
import { ActionSuccessComponent } from './components/modals/action-success/action-success.component';
import { AuthInterceptor } from '../interceptors/auth.interceptor';
import { InvalidTokenComponent } from './components/invalid-token/invalid-token.component';
import { providerToken } from 'src/environments/environment';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
@NgModule({
  declarations: [
    ConversationHeaderComponent,
    ConversationComponent,
    ConversationListComponent,
    MessageComponent,
    MessageInputComponent,
    ChatComponent,
    NotificationComponent,
    ContactSupportComponent,
    FilterPipe,
    ActionConfirmComponent,
    ActionSuccessComponent,
    InvalidTokenComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ChatRoutingModule,
    FormsModule,
    InfiniteScrollModule,
    HttpClientModule,
    PickerModule,
RecaptchaV3Module
  ],
  exports: [
    ChatComponent,
    ConversationHeaderComponent,
    HttpClientModule 
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
{ 
      provide: RECAPTCHA_V3_SITE_KEY, 
      useValue: providerToken.token
    }
  ],
})
export class ChatModule { }


