import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';

declare const grecaptcha: any;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private token: any = '';
  constructor(private recaptchaV3Service: ReCaptchaV3Service) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url.includes('layer7-wa-upload-files') ||
      request.url.includes('layer7-whatsapp-upload-files') ||
      request.url.includes('assets')
    ) {
      // Do not set the Authorization header for Amazon URLs
      return next.handle(request);
    }

    return this.recaptchaV3Service.execute('homepage').pipe(
      switchMap((token: string) => {
        this.token = token;

        const newRequest = request.clone({
          setHeaders: {
            Authorization: `${this.token}`,
          },
        });
        return next.handle(newRequest);
      })
    );
  }
}
