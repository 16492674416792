import { Injectable } from '@angular/core';
import {
  Firestore,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  QuerySnapshot,
} from 'firebase/firestore';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  private db: Firestore;
  private cachedData: Map<string, any[]> = new Map();
  private isFetching: Map<string, boolean> = new Map();

  constructor(private firebaseService: FirebaseService) {
    this.db = this.firebaseService.db;
  }

  public getCollection(path: string) {
    return collection(this.db, path);
  }

  public async getDocuments(collectionPath: string, queryConstraints: any[]) {
		const cacheKey = this.getCacheKey(collectionPath, queryConstraints);
    if (this.cachedData.has(cacheKey) && !this.isFetching.get(cacheKey)) {
      return this.cachedData.get(cacheKey) || [];
    }

    this.isFetching.set(cacheKey, true);

    const q = query(this.getCollection(collectionPath), ...queryConstraints);
    const snapshot = await getDocs(q);
    const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    this.cachedData.set(cacheKey, data);
    this.isFetching.set(cacheKey, false);

    return data;
  }

  public subscribeToCollection(
    collectionPath: string,
    queryConstraints: any[],
    callback: (snapshot: QuerySnapshot) => void
  ) {
    const q = query(this.getCollection(collectionPath), ...queryConstraints);
    return onSnapshot(q, callback);
  }

  public orderByTimestamp(limitTo: number = 100) {
    return [orderBy('timestamp', 'desc'), limit(limitTo)];
  }

  public whereActive(isActive: boolean) {
    return where('active', '==', isActive);
  }

	private getCacheKey(collectionPath: string, queryConstraints: any[]): string {
    return `${collectionPath}-${queryConstraints.map(constraint => constraint.toString()).join('-')}`;
  }
}
