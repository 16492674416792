<div class="container">
  <mat-icon
      aria-hidden="false"
      aria-label="For change to notes"
      [svgIcon]="'bubbles'"
      class="img"
    ></mat-icon>
    <div mat-dialog-content class="modalText">
      <p>{{ data.message }}</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="cancel()">
        Aceptar
      </button>
    </div>
  </div>
