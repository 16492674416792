export const validateFileType = (fileList: FileList) => {
    const file = fileList[0];

    const fileData = {
        fileName: file.name,
        mimeType: file.type,
        type: file.type.split('/')[0] === 'application' ? 'document' : file.type.split('/')[0],
        size: file.size,
        file
    }

    return fileData;
}
