<div class="flex-section">
  <div class="container">
    <div *ngIf="conversation.expires" class="flex-section">
      <span class="last-message-time">{{
        +conversation.timestamp * 1000 | date : "dd MMM, h:mm"
      }}</span>
      <div
        *ngIf="conversation.newMessagesCount! > 0"
        [matBadge]="conversation.newMessagesCount"
        matBadgePosition="after"
        matBadgeSize="small"
        matBadgeOverlap="false"
      ></div>
    </div>
    <span
      *ngIf="conversation.expires"
      class="flex-section"
      [ngClass]="
        conversation.expires < conversation.timeLimit!
          ? 'blueIcon'
          : 'timeAlert'
      "
    >
      <mat-icon class="icon-schedule">schedule</mat-icon>
      <span *ngIf="conversation.diffDays > 0"
        >{{ conversation.diffDays }} d.&nbsp;</span
      >
      <span *ngIf="conversation.diffHrs > 0">
        {{ conversation.diffHrs }} h.&nbsp;</span
      >
      <span> {{ conversation.diffMins }} m. atrás </span>
    </span>
  </div>
</div>
