<mat-grid-list [cols]="5" class="container" [rowHeight]="getRowHeight()" *ngIf="getGridCols() === 4">
    <mat-grid-tile
        colspan="2"
        [rowspan]="3"
        class="conversation-list-fullScreen"
    >
      <app-conversation-list></app-conversation-list>
    </mat-grid-tile>
    <mat-grid-tile
        colspan="3"
        [rowspan]="3"
        class="chat-container-fullScreen"
    >
      <app-conversation></app-conversation>
    </mat-grid-tile>
  </mat-grid-list>

  <div class="wrapper" *ngIf="getGridCols() < 4">
    <div  class="conversation-list">
      <app-conversation-list></app-conversation-list>
    </div>
    <div class="chat-container">
      <app-conversation></app-conversation>
    </div>
  </div>


