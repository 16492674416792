import { Component, OnInit, inject } from '@angular/core';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ChatService } from 'src/app/services/chat.service';
import { ActivatedRoute } from '@angular/router';
import { Agent } from 'src/app/interfaces/conversation.interface';
import { HelperService } from 'src/app/services/helper.service';
import { MatDialog } from '@angular/material/dialog';
import { ContactSupportComponent } from '../../components/contact-support/contact-support.component';
import { catchError, of } from 'rxjs';

declare const grecaptcha: any;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit {
  public showNotification: boolean = false;

  public horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  public verticalPosition: MatSnackBarVerticalPosition = 'top';

  public helperService = inject(HelperService);
  public chatService = inject(ChatService);
  private activatedRoute = inject(ActivatedRoute);
  public dialog = inject(MatDialog);
  public accessToken: any = '';

  constructor() {}

  ngOnInit(): void {
    this.chatService.isAuthenticated.subscribe(isAuth => {
      if (isAuth) { 
        this.activatedRoute.queryParams.subscribe((res) => {
          const accountId: string = res['accountId'];
  
          if (accountId !== undefined) {
            this.getCompanyData(accountId).subscribe((company: any) => {
                if (
                  company.hasOwnProperty('phoneNumberId') &&
                  company['phoneNumberId'] !== undefined &&
                  company['phoneNumberId'] !== ''
                ) {
                  const agentData: Agent = {
                    id: res['agentId'],
                    name: res['agentName'],
                    phoneNumberId: company['phoneNumberId'],
                    expiresConversations: company['sla'],
                  };
                  this.chatService.getEntryMessage(agentData.phoneNumberId);
                  this.helperService.setAgentInfo(agentData);
                }
              });          
          } else {
            console.log('ERROR');
          }
        });
      }
    });
  }

  getGridCols(): number {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 576) {
      // For small screens (e.g., mobile)
      return 2;
    } else if (screenWidth <= 992) {
      // For medium screens (e.g., tablets)
      return 3;
    } else {
      // For larger screens (e.g., desktop)
      return 4;
    }
  }

  getRowHeight(): string {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 576) {
      // For small screens (e.g., mobile)
      return '15%';
    } else if (screenWidth <= 992) {
      // For medium screens (e.g., tablets)
      return '33%';
    } else {
      // For larger screens (e.g., desktop)
      return '100%%';
    }
  }

  getCompanyData(accountId: any){
    return this.chatService.getCompanyData(accountId).pipe(
      catchError((error) => {
        this.dialog.open(ContactSupportComponent, {
          height: '250px',
          width: '500px',
        });
        const agentData: Agent = {
          id: '',
          name: '',
          phoneNumberId: '',
          expiresConversations: '',
        };

        this.helperService.setAgentInfo(agentData);

        return of({});
      })
    )
    }
}
