<div class="conversation">
  <div *ngIf="selectedConversation.profileName !== ''">
    <mat-card
      *ngFor="let message of selectedConversation.messages"
      [ngClass]="
        { whatsapp: 'message-card-right',
          WhatsApp: 'message-card-left',
          internal: 'message-card-agent'
        }[message.type]">
      <p
        class="message-card-name"
        *ngIf="message.type === 'internal' || message.type === 'whatsapp'"
      >
        {{ message.agentName }}
      </p>
      <div
        class="message-card-content"
        *ngIf="!message.mediaId"
        id="messagesContainer"
      >
        <p
          class="message-card-text"
          *ngIf="
            (message.messageBody | slice : 0 : 8) !== 'https://' &&
            (message.messageBoddy | slice : 0 : 8) !== 'https://'
          "
        >
          {{ message.messageBody || message.messageBoddy }}
        </p>
        <a
          [href]="message.messageBody"
          target="_blank"
          class="message-card-text"
          *ngIf="
            (message.messageBody | slice : 0 : 8) === 'https://' ||
            (message.messageBoddy | slice : 0 : 8) === 'https://'
          "
          >{{ message.messageBody || message.messageBoddy }}</a
        >
        <br />
      </div>

      <div class="message-card-content-file" *ngIf="message.mediaId">
        <p *ngIf="message.mediaId" class="message-card-text">
          {{ message.messageBody || message.messageBoddy || message.caption }}
        </p>
        <div class="fileContainer">
          <div [ngSwitch]="message.mediaType || message.messageType">
            <mat-icon *ngSwitchCase="'audio'" class="messageIcon">volume_down</mat-icon>
            <mat-icon *ngSwitchCase="'document'" class="messageIcon">file_present</mat-icon>
            <mat-icon *ngSwitchCase="'image'" class="messageIcon">wallpaper</mat-icon>
            <mat-icon *ngSwitchCase="'video'" class="messageIcon">play_circle_outline</mat-icon>
          </div>
          <span class="textFile">{{ message.fileName }}</span>
          <button
            mat-icon-button
            (click)="downloadFile(message.mediaId!, message.fileName)"
          >
            <mat-icon>download</mat-icon>
          </button>
        </div>
        <br />
      </div>

      <div class="message-card-time">
        <span *ngIf="message.currentDate! >= 24">{{ getDateInMiliseconds(message.timestamp) | date : "short" : "UTC-6" }}</span>
        <span *ngIf="message.currentDate! < 24">{{ getDateInMiliseconds(message.timestamp) | date : "hh:mm a" : "UTC-6" }}</span>
        <span *ngIf="!message.sendStatus || message.sendStatus === 'success'"><mat-icon>done_all</mat-icon></span>
        <span *ngIf="message.sendStatus === 'pending'"><mat-icon>schedule</mat-icon></span>
        <span *ngIf="message.sendStatus === 'failed'"><mat-icon>error</mat-icon></span>
      </div>

    </mat-card>
  </div>
</div>
