import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterList'
})
export class FilterPipe implements PipeTransform {

  transform(conversations: any[] | undefined, currentAgentId: string, isAssigned?: boolean, active?: boolean): any[] {
    let filteredConversatios: any[] | undefined = [];

    if(isAssigned) {
      filteredConversatios = conversations?.filter((conversation: any) => 
      conversation.hasOwnProperty('agentsSuscribed') && conversation.agentsSuscribed.length > 0?
      conversation :
      null
      )

      filteredConversatios = filteredConversatios?.filter((conversation: any) => 
        conversation.agentsSuscribed.includes(currentAgentId) ?
        conversation :
        null
      )
    } else {
      filteredConversatios = conversations;
      if(filteredConversatios?.flat().length! <= 0) return [];
      if(currentAgentId != ''){
        filteredConversatios?.forEach((filteredConversation: any, index: number) => {
          if(!filteredConversation.hasOwnProperty('agentsSuscribed') || filteredConversation.agentsSuscribed.length === 0) {
            filteredConversatios![index]!.agentsSuscribed = [];
          } 
        })
        filteredConversatios = filteredConversatios?.filter((conversation: any) => 
          !conversation.agentsSuscribed.includes(currentAgentId) ?
          conversation :
          null
        )
      }
    }

    filteredConversatios = filteredConversatios?.filter((conversation: any) => 
      conversation.active == active      
    )

    return filteredConversatios as any[];

  }

}
