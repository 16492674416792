import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  Conversation,
  FileData,
} from 'src/app/interfaces/conversation.interface';

import { saveAs } from 'file-saver-es';

import { ChatService } from 'src/app/services/chat.service';
import { HelperService } from 'src/app/services/helper.service';
import { MatDialog } from '@angular/material/dialog';
import { ActionSuccessComponent } from '../modals/action-success/action-success.component';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
})
export class MessageComponent implements OnInit, DoCheck {
  
  @Input() selectedConversation: Conversation = {};
  @Output() toEnd = new EventEmitter<boolean>();

  public helperService = inject(HelperService);
  public chatService = inject(ChatService);
  private dialog = inject(MatDialog);

  public phoneNumberId: string | undefined = '';

  ngOnInit() {
    this.phoneNumberId = this.selectedConversation.phoneNumberId;

    this.helperService.messages.subscribe((messages) => {
      if (messages.length > 0) {
          this.selectedConversation.messages = messages;
          const a = this.setCurrentDate();
          this.selectedConversation.messages = a;
        }
        this.toEnd.emit(true);
    });
  }

  ngDoCheck(): void {
    this.setCurrentDate();
  }

  getDateInMiliseconds(dateSeconds: number): number {
    return dateSeconds.toString().length === 10
      ? dateSeconds * 1000
      : dateSeconds;
  }

  setCurrentDate(): any {
     this.selectedConversation.messages!.forEach((message) => {
      message.fileName = message.fileName
        ? message.fileName
        : 'Archivo adjunto';
      message.currentDate = Math.floor(message.timestamp / 86400000);
    });
    return this.selectedConversation.messages;
  }

  downloadFile(mediaId: string, filename: string) {
    const objectToSend: FileData = {
      mediaId,
      phoneNumberId: this.phoneNumberId,
      filename,
    };

    this.chatService.getMediaFileById(objectToSend).subscribe({
      next: (res: any) => {
        saveAs(res.urlDownload, mediaId);
      },
      error: (error: any) => {
        this.dialog.open(ActionSuccessComponent, {
          height: '250px',
          width: '500px',
          data: {
            message: error.error,
          }
        });
      }
    });

  }
}
