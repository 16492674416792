import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private notificationList: any[] = [];
  private newNotifications: any[] = [];
  private reset: boolean = false;

  private notification$ = new BehaviorSubject<any>([]);
  public notification = this.notification$.asObservable();

  public setNotifications(phoneNumber: string, isReset: boolean = false): void {
    if (isReset) {
      this.resetNotifications(phoneNumber);
    } else {
      this.addNotification(phoneNumber);
    }

    this.notification$.next(this.newNotifications);
  }

  private addNotification(phoneNumber: string): void {
    const existingNotification = this.newNotifications.find(notification => notification.phoneNumber === phoneNumber);
    if (existingNotification) {
      existingNotification.count++;
    } else {
      this.newNotifications.push({
        phoneNumber: phoneNumber,
        count: 1
      });
    }
  }

  getEntryNotifications(aux: any[], phoneNumber: string) {
    let auxArray: any = [];

    Object.entries(aux).forEach((entries) => {
      const notificationObject = {
        phoneNumber: entries[0],
        count: entries[1],
      };

      auxArray.push(notificationObject);
      auxArray.some((notification: any, index: number) => {
        if (notification.phoneNumber === phoneNumber) {
          this.newNotifications[index] = notification;
        }
      });

      this.newNotifications = auxArray;
    });
  }

  resetNotifications(phoneNumber: string): void {
    const index = this.newNotifications.findIndex(notification => notification.phoneNumber === phoneNumber);
    if (index !== -1) {
      this.newNotifications[index].count = 0;
    }
  }

  ngOnDestoy() {
    this.notification$.next([]);
    this.notification$.unsubscribe();
  }
}
