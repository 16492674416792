<div class="conversation-header"> 
  <mat-toolbar style="display: flex; align-items: space-around;">
    <div *ngIf="username; else noName" class="headerContainer">
      <div class="headerText">
        <strong><span>{{ username }}</span></strong>
        <small>{{ phoneNumber }}</small>
      </div>
    </div>
    <div *ngIf="username;" class="buttonContainer">
      <!-- ----------- Archived conversation ---------- -->
      <button *ngIf="isAssigned && active" mat-raised-button color="success" (click)="archiveConversation()">
        <mat-icon>keyboard_tab</mat-icon>
        Finalizar y archivar
      </button>
      <!-- ----------- Reactive conversation ---------- -->
      <button *ngIf="!active" mat-raised-button color="success" (click)="reactivateConversation()">
        <mat-icon [svgIcon]="'arrow'"></mat-icon>
        Reactivar chat
      </button>
    </div>
    
    <ng-template #noName>
      <div class="headerText">
      Bienvenido
    </div>
    </ng-template>
  </mat-toolbar>
</div>



