
<mat-form-field
focused="false"
[ngClass]="isNotes ? 'conversation-note-input' : 'conversation-input'"
>
  <div class="iconsContainer">
    <mat-icon
      [ngClass]="isNotes === true ? 'blueIcon' : 'icon'"
      (click)="changeToNotes(!isNotes)"
      aria-hidden="false"
      aria-label="For change to notes"
      [svgIcon]="'notes'"
      *ngIf="(fileType === ''
      || fileType === 'document'
      || fileType === 'image'
      || fileType === 'video') && this.selectedConversation.isAssigned === true"
    ></mat-icon>
    <mat-icon
      aria-hidden="false"
      [matMenuTriggerFor]="menu"
      aria-label="For send a file"
      [svgIcon]="'attach'"
      *ngIf="!fileName && this.selectedConversation.isAssigned === true"
    ></mat-icon>
    <mat-menu #menu="matMenu">
      <button mat-menu-item onclick="document.getElementById('audioInput').click()">
        <mat-icon [svgIcon]="'audio'"></mat-icon>
        <input #audioFile type="file" hidden id="audioInput" accept="audio/aac,audio/mp3,audio/mpeg,audio/amr,audio/ogg" (change)="uploadAudioFile($event)">
        <span>Audio</span>
      </button>
      <button mat-menu-item onclick="document.getElementById('imageInput').click()">
        <mat-icon [svgIcon]="'gallery'"></mat-icon>
        <input #ImageFile type="file" hidden id="imageInput" accept="image/jpeg,image/png,image/jpg" (change)="uploadImageFile($event)">
        <span>Galería</span>
      </button>
      <button mat-menu-item onclick="document.getElementById('documentInput').click()">
        <mat-icon [svgIcon]="'document'" ></mat-icon>
        <input #docFile type="file" hidden id="documentInput"  accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf" (change)="uploadDocFile($event)">
        <span>Documentos</span>
      </button>
      <button mat-menu-item onclick="document.getElementById('videoInput').click()">
        <mat-icon [svgIcon]="'video'" ></mat-icon>
        <input #videoFile type="file" hidden id="videoInput"  accept="video/mp4,video/3gp" (change)="uploadVideoFile($event)">
        <span>Video</span>
      </button>
    </mat-menu>

    <mat-icon
      aria-hidden="false"
      aria-label="Para seleccionar emoji"
      [matMenuTriggerFor]="emojiMenu"
      [svgIcon]="'emoji'"
      *ngIf="(fileType === '' || fileType === 'document' || fileType === 'image' || fileType === 'video') && this.selectedConversation.isAssigned === true"
    ></mat-icon>
    <mat-menu #emojiMenu="matMenu">
      <emoji-mart
      class="emoji-mart"
      set="facebook"
      (emojiSelect)="addEmoji($event);"
      title="Selecciona tu emoji…"
      [showPreview]="false"
      [darkMode]="false"
      [isNative]="true"
      [sheetSize]="32"
      [perLine]="6"
      [totalFrequentLines]="2"
      (click)="$event.stopPropagation()"
    ></emoji-mart>
  </mat-menu>
  </div>

  <div class="input-container">
    <input
      *ngIf="!this.selectedConversation.isAssigned"
      matInput
      placeholder="
        No estás asignado a esta conversación, para poder enviar mensajes
      "
      readonly="true"
    />
    <input
      *ngIf="(fileType === '' || fileType === 'document' || fileType === 'image' || fileType === 'video') && this.selectedConversation.isAssigned === true"
      matInput
      [(ngModel)]="newMessage"
      [placeholder]="
        isNotes ? 'Escribe aquí tu nota interna...' : 'Escribe aquí tu texto...'
      "
      (keyup.enter)="sendMessage(newMessage)"
    />
    <div [ngClass]="fileName ? 'deleteButton' : '' ">
    <input
      *ngIf="fileName"
      matInput
      [placeholder]="fileName"
      readonly="true"
      (keyup.enter)="sendMedia()"
    />
    <button mat-icon-button *ngIf="fileName" (click)="cleanFiles()">
      <mat-icon>remove_circle_outline</mat-icon>
    </button>
    </div>
    <button
      *ngIf="!fileName && this.selectedConversation.isAssigned === true"
      mat-raised-button
      matSuffix
      color="success"
      class="sendButton"
      (click)="sendMessage(newMessage)"
      [disabled]="!newMessage.trim().length"
    >
      Enviar
    </button>
    <button
      *ngIf="fileName && this.selectedConversation.isAssigned === true"
      mat-raised-button
      matSuffix
      color="success"
      class="sendButton"
      (click)="sendMedia()"
      [disabled]="!fileName || this.selectedConversation.isAssigned !== true"
    >
      Enviar
    </button>
    <button
      *ngIf="this.selectedConversation.isAssigned === false"
      mat-raised-button
      matSuffix
      color="success"
      class="sendButton"
      (click)="assignMe()"
    >
      Asignarme
    </button>
  </div>
</mat-form-field>
