import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

interface DialogData {
  message: string;
}

@Component({
  selector: 'app-action-success',
  templateUrl: './action-success.component.html',
  styleUrls: ['./action-success.component.css']
})
export class ActionSuccessComponent {

  constructor(
    public dialogRef: MatDialogRef<ActionSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'bubbles',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../assets/icons/bubbles.svg'
      )
    );
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
