<div class="wrapper">
  <mat-form-field class="conversation-list-header">
    <input matInput [(ngModel)]="searchByName"
      (keyup.enter)="searchConversation(searchByName)"
    />
      <mat-icon
      class="searchIcon"
      matSuffix
      (click)="searchConversation(searchByName)"
      [svgIcon]="'search'"></mat-icon>
  </mat-form-field>
</div>

<div class="conversation-list">
  <mat-list>
    <div class="accordion-container">
          <mat-accordion class="accordion">
            <mat-expansion-panel
              hideToggle="true"
              class="mat-elevation-z0"
              [expanded]="unassigned"
            >
              <mat-expansion-panel-header (click)="togglePanel('unassigned')">
                <div class="title">
                <mat-panel-title> Sin asignar </mat-panel-title>
                </div>
                <div class="icon">
                <mat-panel-description>
                  <mat-icon *ngIf="!unassigned" class="arrow">
                    keyboard_arrow_down
                  </mat-icon>
                  <mat-icon *ngIf="unassigned" class="arrow">
                    keyboard_arrow_up
                  </mat-icon>
                </mat-panel-description>
              </div>
              </mat-expansion-panel-header>
              <mat-list role="list" class="filteredContainer" style="margin-top: 0px;">
                <div class="conversation-list-container-signed">
                <mat-list-item
                  role="listitem"
                  *ngFor="let conversation of conversations | filterList: this.agentData.id: false : true; trackBy: trackByFn"
                  (click)="selectConversation(conversation, '_unassigned', true)"
                  [id]="conversation.phoneNumber + '_unassigned'"
                >
                  <div class="conversationCard">
                    <div class="conversationUser">
                      <h3 matListItemTitle>{{ conversation.name }}</h3>
                    </div>
                    <app-notification class="conversationStatus" [conversation]="conversation"></app-notification>
                  </div>
                </mat-list-item>
              </div>
              </mat-list>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion class="accordion">
            <mat-expansion-panel
              hideToggle="true"
              [expanded]="assigned"
              class="mat-elevation-z0"
            >
              <mat-expansion-panel-header (click)="togglePanel('assigned')">
                <div class="title">
                <mat-panel-title> Asignadas a mi </mat-panel-title>
                </div>
                <div class="icon">
                <mat-panel-description>
                  <mat-icon *ngIf="!assigned" class="arrow">
                    keyboard_arrow_down
                  </mat-icon>
                  <mat-icon *ngIf="assigned" class="arrow">
                    keyboard_arrow_up
                  </mat-icon>
                </mat-panel-description>
              </div>
              </mat-expansion-panel-header>
              <mat-list role="list" class="filteredContainer" style="margin-top: 0px;">
                <div class="conversation-list-container-signed">
                <mat-list-item
                  role="listitem"
                  *ngFor="let conversation of conversations | filterList: this.agentData.id: true : true; trackBy: trackByFn"
                  (click)="selectConversation(conversation, '_assigned', true)"
                  [id]="conversation.phoneNumber + '_assigned'"
                >
                  <div class="conversationCard">
                    <div class="conversationUser">
                      <h3 matListItemTitle>{{ conversation.name }}</h3>
                    </div>
                    <app-notification class="conversationStatus" [conversation]="conversation"></app-notification>
                  </div>
                </mat-list-item>
              </div>
              </mat-list>
            </mat-expansion-panel>
          </mat-accordion>
    </div>
  </mat-list>

  <mat-list role="list">
    <!-- ---------- Archived conversations ---------- -->
    <div mat-subheader (click)="toggleConversations()" class="mt-3 cursor-pointer" style="display: flex;">
      <mat-icon *ngIf="showArchivedConversations" class="arrow-icon">arrow_back</mat-icon>
      Conversaciones Archivadas
    </div>
    <div *ngIf="showArchivedConversations" class="conversation-list-container-archived">
      <mat-list-item
        role="listitem"
        *ngFor="let conversation of conversations | filterList: '' : false : false; trackBy: trackByFn"
        (click)="selectConversation(conversation, '_archived', true)"
        [id]="conversation.phoneNumber + '_archived'"
      >
        <div class="conversationCard">
          <div class="conversationUser">
            <h3 matListItemTitle>{{ conversation.name }}</h3>
          </div>
          <app-notification class="conversationStatus" [conversation]="conversation"></app-notification>
        </div>
      </mat-list-item>
    </div>

    <!-- ---------- All conversations ---------- -->
    <div mat-subheader (click)="toggleConversations()" class="cursor-pointer">Todas las conversaciones</div>
    <div
      class="conversation-list-container"
      *ngIf="showAllConversations"
    >
      <mat-list-item
        role="listitem"
        *ngFor="let conversation of conversations | filterList: '' : undefined : true; trackBy: trackByFn"
        (click)="selectConversation(conversation, '_all', true)"
        [id]="conversation.phoneNumber + '_all'"
      >
        <div class="conversationCard">
          <div class="conversationUser">
            <h3 matListItemTitle>{{ conversation.name }}</h3>
          </div>
          <app-notification class="conversationStatus" [conversation]="conversation"></app-notification>
        </div>
      </mat-list-item>
    </div>
  </mat-list>
</div>
