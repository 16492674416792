import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { ActionConfirmComponent } from '../modals/action-confirm/action-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { ActionSuccessComponent } from '../modals/action-success/action-success.component';
import { CONFIRM_TEXT, QUESTION_TEXT, SUCCESS_TEXT } from 'src/app/helpers/consts';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-conversation-header',
  templateUrl: './conversation-header.component.html',
  styleUrls: ['./conversation-header.component.css']
})
export class ConversationHeaderComponent {
  @Input() username: string | undefined = '';
  @Input() state: string | undefined = '';
  @Input() phoneNumber: string | undefined = '';
  @Input() conversationInfo: [string | undefined, string | undefined] = ['', ''];
  @Output() cleanStoredConversation = new EventEmitter<boolean>();
  @Input() isAssigned: boolean | undefined = false;
  @Input() active: boolean | undefined = true;

  private chatService = inject(ChatService);
  private dialog = inject(MatDialog);

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../assets/icons/arrow.svg'
      )
    );
  }

  archiveConversation(): void {
    const dialogRef = this.dialog.open(ActionConfirmComponent, {
      height: '300px',
      width: '550px',
      data: {
        title: QUESTION_TEXT.store,
        text: CONFIRM_TEXT.store
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result && this.conversationInfo[0] && this.conversationInfo[1]) {
        this.chatService.storeConversation(
          this.conversationInfo[0]!,
          this.conversationInfo[1]!,
          false
        ).subscribe((res) => {
          if (res) {
            this.cleanStoredConversation.emit(true);
            this.openArchiveSuccessDialog();
          }
        });
      }
    });
  }
  
  reactivateConversation(): void {
    const dialogRef = this.dialog.open(ActionConfirmComponent, {
      height: '300px',
      width: '550px',
      data: {
        title: QUESTION_TEXT.reactivate,
        text: CONFIRM_TEXT.reactivate
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result && this.conversationInfo[0] && this.conversationInfo[1]) {
        this.chatService.storeConversation(
          this.conversationInfo[0]!,
          this.conversationInfo[1]!,
          true
        ).subscribe((res) => {
          if (res) {
            this.cleanStoredConversation.emit(true);
            this.openReactivateSuccessDialog();
          }
        });
      }
    });
  }

  openArchiveSuccessDialog(): void {
    this.dialog.open(ActionSuccessComponent, {
      height: '250px',
      width: '500px',
      data: {
        message: SUCCESS_TEXT.store
      }
    });
  }
  
  openReactivateSuccessDialog(): void {
    this.dialog.open(ActionSuccessComponent, {
      height: '250px',
      width: '500px',
      data: {
        message: SUCCESS_TEXT.reactivate
      }
    });
  }
}