import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ContactSupportComponent } from '../contact-support/contact-support.component';

@Component({
  selector: 'app-invalid-token',
  templateUrl: './invalid-token.component.html',
  styleUrls: ['./invalid-token.component.css']
})
export class InvalidTokenComponent {

  dialogRef = inject(MatDialogRef<ContactSupportComponent>);

  onClick(): void {
    this.dialogRef.close();
  }

}
