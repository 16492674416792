import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.css']
})
export class ContactSupportComponent {

  dialogRef = inject(MatDialogRef<ContactSupportComponent>);

  onClick(): void {
    this.dialogRef.close();
  }

}
