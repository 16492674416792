import { Component, DoCheck, Input, OnInit, inject } from '@angular/core';
import { ConversationView } from 'src/app/interfaces/conversation.interface';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit, DoCheck {

  @Input() conversation !: ConversationView;
  
  public notificationService = inject(NotificationsService)
  public notifications !: any;
  public isShow: boolean = false;
  public nowDate = new Date();

  ngOnInit() {
    this.conversation.newMessagesCount = 0;
    this.notificationService.notification.subscribe(res => {
      const relevantNotification = res.find((element: any) => element.phoneNumber == this.conversation.phoneNumber);
      this.conversation.newMessagesCount = relevantNotification ? relevantNotification.count : 0;
    });
  }

  ngDoCheck() {
    return this.conversation;
  }
}
