import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

interface DialogData {
  title: string;
  text: string;
  name: string;
}

@Component({
  selector: 'app-action-confirm',
  templateUrl: './action-confirm.component.html',
  styleUrls: ['./action-confirm.component.css']
})

export class ActionConfirmComponent {

  constructor(
    public dialogRef: MatDialogRef<ActionConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'bubbles',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../assets/icons/bubbles.svg'
      )
    );
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
