import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, interval, map } from 'rxjs';
import {
  Agent,
  ConversationView,
} from 'src/app/interfaces/conversation.interface';
import { ChatService } from 'src/app/services/chat.service';
import { HelperService } from 'src/app/services/helper.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import {
  MILLISECONDS_PER_SECOND,
  SECONDS_PER_MINUTE,
  MILLISECONDS_PER_MINUTE,
  MILLISECONDS_PER_HOUR,
  MILLISECONDS_PER_DAY,
} from 'src/app/helpers/consts';

@Component({
  selector: 'app-conversation-list',
  templateUrl: './conversation-list.component.html',
  styleUrls: ['./conversation-list.component.css'],
})
export class ConversationListComponent implements OnInit {
  @ViewChild('clientName', { read: ElementRef }) clientName!: ElementRef;

  public conversations: ConversationView[] | undefined = [];
  public conversationsAux: ConversationView[] | undefined = [];
  public currentConversation!: ConversationView;
  public searchByName: string = '';
  public agentData!: Agent;
  public conversationList!: Promise<any>;
  public showArchivedConversations = false;
  public showAllConversations = true;

  public countdownSubscription!: Subscription;
  public minutesToLeft: number = 0;
  private firstTime: boolean = true;

  public assigned: boolean = false;
  public unassigned: boolean = true;

  private subscriptions: Subscription[] = [];

  constructor(
    private helperService: HelperService,
    private chatService: ChatService,
    private notificationService: NotificationsService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'search',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../assets/icons/search.svg'
      )
    );
  }

  togglePanel(panel: string): void {
    if (panel === 'assigned') {
      this.assigned = !this.assigned;
      this.unassigned = false;
    } else if (panel === 'unassigned') {
      this.unassigned = !this.unassigned;
      this.assigned = false;
    }
  }

  toggleConversations() {
    this.showArchivedConversations = !this.showArchivedConversations
    this.showAllConversations = !this.showAllConversations
  }

  ngOnInit(): void {
    this.helperService.agentInfo.subscribe((agent) => {
      this.agentData = agent;
      if (this.agentData.id != '' && this.firstTime) {
        this.chatService.getConversations(this.agentData.phoneNumberId)
        .then((allConversations) => {
          this.conversations = allConversations;
          this.conversationsAux = allConversations;
          this.conversations?.forEach((res) => {
            res.timeLimit =
              Number(agent.expiresConversations) *
              SECONDS_PER_MINUTE *
              MILLISECONDS_PER_SECOND;
            res.expires = Number(
              new Date().getTime() -
              Number(res.timestamp) * MILLISECONDS_PER_SECOND
            );
            res.diffDays = Math.floor(res.expires / MILLISECONDS_PER_DAY); // days
            res.diffHrs = Math.floor(
              (res.expires % MILLISECONDS_PER_DAY) / MILLISECONDS_PER_HOUR
            ); // hours
            res.diffMins = Math.round(
              ((res.expires % MILLISECONDS_PER_DAY) % MILLISECONDS_PER_HOUR) /
              MILLISECONDS_PER_MINUTE
            ); // minutes
          });
        });

      } else {
        this.helperService.setConversationList([], true);
        this.conversations = [];
      }
    });

    this.helperService.conversationView.subscribe((res) => {
      if (this.updateConversationView(res)) {
        this.conversations = res;
      }
    });

    this.helperService.stopper.subscribe((res) => {
      if (this.currentConversation !== undefined && res) {
        this.conversations?.filter((conversation) => {
          if (conversation.id === this.currentConversation.id) {
            conversation.timestamp = new Date().getTime();
          }
        });
      }
    });

    this.countdownSubscription = interval(1000)
      .pipe(
        map(() => {
          this.conversations?.forEach((res) => {
            res.expires =
              String(res.timestamp).length === 10
                ? Number(
                  new Date().getTime() -
                  Number(res.timestamp) * MILLISECONDS_PER_SECOND
                )
                : Number(new Date().getTime() - Number(res.timestamp));

            res.timeLimit = !res.timeLimit
              ? Number(this.agentData.expiresConversations) *
              SECONDS_PER_MINUTE *
              MILLISECONDS_PER_SECOND
              : res.timeLimit;

            res.diffDays = Math.floor(res.expires / MILLISECONDS_PER_DAY); // days
            res.diffHrs = Math.floor(
              (res.expires % MILLISECONDS_PER_DAY) / MILLISECONDS_PER_HOUR
            ); // hours
            res.diffMins = Math.round(
              ((res.expires % MILLISECONDS_PER_DAY) % MILLISECONDS_PER_HOUR) /
              MILLISECONDS_PER_MINUTE
            ); // minutes

            this.checkExpiredConversation(res);
          });
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  selectConversation(
    conversation: ConversationView,
    type: string,
    isSelected: boolean
  ) {
    this.currentConversation = conversation;
    this.helperService.seeConversation.subscribe((res) => {
      res.from !== conversation.phoneNumber
        ? document
          .getElementById(conversation.phoneNumber + type)
          ?.classList.remove('selected')
        : document
          .getElementById(conversation.phoneNumber + type)
          ?.classList.add('selected');
    });
    this.helperService.cleanCurrentConversation(false);
    this.notificationService.setNotifications(conversation.phoneNumber, true);
    this.chatService
      .getMessagesByPhoneId(
        this.agentData.phoneNumberId,
        conversation.phoneNumber
      )
      .then((r) => {
        r[0].agentsSuscribed = this.currentConversation.agentsSuscribed ?? [];
        this.helperService.seeCompleteConversation(
          r,
          isSelected,
          conversation.name,
          conversation.isNotes,
          isSelected,
          conversation,
          conversation.active
        );
      });
  }

  checkExpiredConversation(conversation: ConversationView) {
    if (conversation.expires === conversation.timeLimit) {
      this.conversations?.filter((filtered, index) => {
        if (conversation.id === filtered.id) {
          this.conversations!.splice(index, 1);
          this.conversations!.push(conversation);
        }
      });
    }
  }

  searchConversation(term: string) {
    let list: ConversationView[] = [];
    if (term === '') {
      this.conversations = this.conversationsAux;
      return;
    }
    this.conversationsAux!.some((result) => {
      if (result.name.toLowerCase().includes(term.toLowerCase())) {
        list = [result];
      }

      if (result.phoneNumber.toLowerCase().includes(term.toLowerCase())) {
        list = [result];
      }
    });
    this.conversations = list;
  }

  private updateConversationView(nuevosDatos: ConversationView[]): boolean {
    if (!this.conversations) {
      return true;
    }
    if (this.conversations.length !== nuevosDatos.length) {
      return true;
    }
    return true;
  }

  trackByFn(_index: number, item: ConversationView): string {
    return item.id;
  }
}