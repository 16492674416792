export const environment = {
  MESSAGE_URL: 'https://wa-api-dev.layer7.tel/messages',
  ENTERPRISE_URL: 'https://wa-api-dev.layer7.tel/enterprises',
  STORE_CONVERSATION: 'https://wa-api-dev.layer7.tel/conversations',
  API_TOKEN_VERIFY: 'https://wa-api-dev.layer7.tel/authorizer/',
};

export const userCredentials = {
  email: 'WhatsApp@layer7.mx',
  password: 'AhmaeN7gae8wee}nujee',
};

export const providerToken = {
  token: '6Lc9rMwnAAAAAI_nc3pYQYrqCyar6tFc_sHKLErp',
};

export const firebaseConfig = {
  apiKey: "AIzaSyBsP78MkZ6PZbw_L0opn00XgZxa1o3VRzs",
  authDomain: "cccwhatsapp.firebaseapp.com",
  databaseURL: "https://cccwhatsapp-default-rtdb.firebaseio.com",
  projectId: "cccwhatsapp",
  storageBucket: "cccwhatsapp.appspot.com",
  messagingSenderId: "1039491761598",
  appId: "1:1039491761598:web:85d07fbf2551a3da2b65ef"
};
