import { Injectable, inject } from '@angular/core';
import { FileData, Message } from '../interfaces/conversation.interface';
import { HttpClient } from '@angular/common/http';
import { where } from 'firebase/firestore';

import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';
import { NotificationsService } from './notifications.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { FirestoreService } from './firestore.service';
import { FirebaseService } from './firebase.service';

declare const grecaptcha: any;

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  public http = inject(HttpClient);
  public helperService = inject(HelperService);
  public notificationService = inject(NotificationsService);
  private firebaseService = inject(FirebaseService);
  private firestoreService = inject(FirestoreService);

  public unsubscribeMessages: any = null;
  public unsubscribeClients: any = null;

  public db: any = null;
  public conversationList: any[] = [];
  public messageList: any[] = [];

  public auth: any = null;
  public appCheck: any = null;
  public token = '';
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscribedConversations: Set<string> = new Set();

  constructor() {
    this.firebaseService
      .signIn()
      .then((userCredential) => {
        this.isLoading.next(!!userCredential.user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }

  get isAuthenticated(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  verifyToken(token: string) {
    return this.http.post(
      `${environment.API_TOKEN_VERIFY}`,
      {
        token,
      },
      { observe: 'response' }
    );
  }

  getCompanyData(accountId: string) {
    const url = `${environment.ENTERPRISE_URL}/enterprise/${accountId}`;
    return this.http.get(url);
  }

  sendMessage(payload: Message) {
    const url = `${environment.MESSAGE_URL}/message`;
    return this.http.post(url, payload);
  }

  storeConversation(phoneNumberId: string, from: string, active: boolean) {
    const url = `${environment.STORE_CONVERSATION}/conversation/${phoneNumberId}?from=${from}&active=${active}`;
    return this.http.put(url, { active });
  }

  assignConversation(phoneNumberId: string, from: string, agentId: string) {
    const url = `${environment.STORE_CONVERSATION}/suscribe/${phoneNumberId}?from=${from}&agentId=${agentId}`;
    return this.http.put(url, null);
  }

  //#region Media steps in ascendent order

  // 1. Get presigned url
  getPresignedURL(fileName: string) {
    return this.http.get(
      `${environment.MESSAGE_URL}/getPresignedURL/${fileName}`,
      { responseType: 'json' }
    );
  }

  // 2. Update file in presigned url
  updateFileInPresignedURL(presignedUrl: string, body: File) {
    return this.http.put(presignedUrl, body, {
      headers: { 'Content-Type': body.type },
    });
  }

  // 3. Send media to S3 /sendMedia
  sendMedia(objectToSend: any) {
    const url = `${environment.MESSAGE_URL}/sendMedia`;
    return this.http.post(url, objectToSend);
  }

  // 4. Get media file by id
  getMediaFileById(body: FileData) {
    const url = `${environment.MESSAGE_URL}/getMedia/${body.phoneNumberId}?mediaId=${body.mediaId}&fileName=${body.filename}`;
    return this.http.get(url);
  }

  //#endregion
  async getConversations(phoneNumberId: string, active?: boolean) {
    const timestamp = Math.floor(Date.now() / 1000) - 100;
    let isFirstLoad = true;
    const queryConstraints =
      active !== undefined ? [this.firestoreService.whereActive(active)] : [];

    const conversations = await this.firestoreService.getDocuments(
      `room/${phoneNumberId}/clients`,
      queryConstraints
    );

    this.conversationList = conversations.map((doc) => ({
      expires: 0,
      ...doc,
    }));

    this.firestoreService.subscribeToCollection(
      `room/${phoneNumberId}/clients`,
      queryConstraints,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const conversation = change.doc.data();
          if (change.type === 'modified' || (change.type === 'added' && !isFirstLoad)) {
            this.subscribeToMessages(conversation, timestamp, phoneNumberId);
          }
        });
        isFirstLoad = false;
      }
    );

    return this.conversationList;
  }

  subscribeToMessages(conversation: any, timestamp: number, phoneNumberId: string) {
    const conversationKey = conversation.phoneNumber; 

    if (this.subscribedConversations.has(conversationKey)) {
      return; 
    }

    this.subscribedConversations.add(conversationKey);

    this.firestoreService.subscribeToCollection(
      `room/${phoneNumberId}/clients/${conversation.phoneNumber}/messages`,
      [where('timestamp', '>=', timestamp)],
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const message = change.doc.data();
            if (message['type'] === 'WhatsApp') {
              this.notificationService.setNotifications(message['from']);
            }
          }
        });
      }
    );
  }

  getEntryMessage(phoneNumberId: string) {
    const collectionPath = `room/${phoneNumberId}/clients`;

    this.unsubscribeClients = this.firestoreService.subscribeToCollection(
      collectionPath,
      [],
      (snapshot) => {
        snapshot.docChanges().forEach((client) => {
          this.handleClientChange(client);
        });
      }
    );
  }

  getEntryMessageByNumber(phoneNumberId: string, phoneNumber: string) {
    if (this.unsubscribeMessages) this.unsubscribeMessages();
    const collectionPath = `room/${phoneNumberId}/clients/${phoneNumber}/messages`;

    this.unsubscribeMessages = this.firestoreService.subscribeToCollection(
      collectionPath,
      [this.firestoreService.orderByTimestamp()],
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const data = change.doc.data();
            this.messageList.push(data);  
            this.helperService.setEntryMessage(this.messageList);
          }
        });
      }
    );
  }

  async getMessagesByPhoneId(phoneNumberId: string, phoneNumber: string) {
    this.messageList = [];

    const queryConstraints = [this.firestoreService.orderByTimestamp()];
    const messages = await this.firestoreService.getDocuments(
      `room/${phoneNumberId}/clients/${phoneNumber}/messages`,
      queryConstraints
    );

    this.messageList = messages.map((doc) => ({
      ...doc,
    }));
    return this.messageList;
  }

  private handleClientChange(client: any) {
    const data = client.doc.data();
    if (client.type === 'added') {
      if (this.conversationList.length == 0) {
        this.helperService.setConversationList(data, false);
      } else {
        const index = this.conversationList.findIndex(
          (r) => r.phoneNumber === data['phoneNumber']
        );
        if (index == -1) {
          this.conversationList.unshift(data);
        }
        this.conversationList[index] = {
          ...this.conversationList[index],
          ...data,
        };
        this.helperService.setConversationList(this.conversationList, false);
      }
    }

    if (client.type === 'modified') {
      const { phoneNumber } = data;
      const index = this.conversationList.findIndex(
        (res) => res.phoneNumber === phoneNumber
      );
      this.conversationList[index]  = { ...this.conversationList[index], ...data};
      this.helperService.setArchivedConversation(this.conversationList[index]);
      this.helperService.setConversationList(this.conversationList, false);
    }

    if (client.type === 'removed') {
      let index = this.conversationList.findIndex(
        (r) => r.phoneNumber === data['phoneNumber']
      );
      this.conversationList[index] = {
        ...this.conversationList[index],
        ...data,
      };
      this.helperService.setConversationList(this.conversationList, false);
      this.helperService.cleanCurrentConversation(true);
    }
  }

  ngDestroy() {
    if (this.unsubscribeMessages) this.unsubscribeMessages();
    if (this.unsubscribeClients) this.unsubscribeClients();
  }
}
