import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatComponent } from './pages/chat/chat.component';

// ?companyId=00002&accountId="076789876"&agentId=00002&agentName=Erick'

export const routes: Routes = [
  { path: '', redirectTo: 'chat', pathMatch: 'full'},
  { path: 'chat', children: [
    {
      path: '?accountId=901&agentId=49899&agentName=Erick', component: ChatComponent
    }
] },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class ChatRoutingModule { }
