import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, switchMap, of } from 'rxjs';
import { ALLOWED_TYPES, CONFIRM_TEXT, MAXIMUM_SIZE, QUESTION_TEXT } from 'src/app/helpers/consts';
import { validateFileType } from 'src/app/helpers/functions';
import { ActionSuccessComponent } from '../modals/action-success/action-success.component';
import {
  Agent,
  Conversation,
  ConversationView,
  InternalMessage,
  Message,
  MessageMedia,
} from 'src/app/interfaces/conversation.interface';
import { ChatService } from 'src/app/services/chat.service';
import { HelperService } from 'src/app/services/helper.service';
import { ActionConfirmComponent } from '../modals/action-confirm/action-confirm.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-message-input',
  templateUrl: './message-input.component.html',
  styleUrls: ['./message-input.component.css'],
})
export class MessageInputComponent implements OnInit {

  @Input() selectedConversation: Conversation = {};
  @Output() isAssignedConversation = new EventEmitter<boolean>();

  public mentionListVisible = false;
  public mentionItems = ['John', 'Jane', 'Alex', 'Sarah'];
  filteredMentionItems: string[] = [];

  @ViewChild('audioFile', { read: ElementRef }) audioInput!: ElementRef;
  @ViewChild('ImageFile', { read: ElementRef }) imageInput!: ElementRef;
  @ViewChild('docFile', { read: ElementRef }) documentInput!: ElementRef;
  @ViewChild('videoFile', { read: ElementRef }) videooInput!: ElementRef;

  public newMessage: string = '';
  public messageObjectToSend!: Message;
  public messageObjectMedia!: MessageMedia;
  public isNotes: boolean = false;

  private chatService = inject(ChatService);
  private helperService = inject(HelperService);
  private dialog = inject(MatDialog);

  public showEmojiPicker = false;
  public fileName: string = '';
  public file !: File;
  public fileType: string = '';

  public agentInfo !: Agent;

  public isAssigned: boolean = false;
  public currentConversation !: ConversationView;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'attach',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../assets/icons/adjuntar.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'audio',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../assets/icons/audio.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'document',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../assets/icons/documento.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'gallery',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../assets/icons/galeria.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'emoji',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../assets/icons/iconsmile.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'notes',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../assets/icons/notas.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'video',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../assets/icons/video.svg'
      )
    );
  }

  ngOnInit(): void {
    this.helperService.agentInfo.subscribe( agent => {
      this.agentInfo = agent;
    })

    this.helperService.seeConversation.subscribe( conversation => {
      this.isNotes = conversation.isNotes!;
      this.selectedConversation = conversation;
      this.selectedConversation.isAssigned = false;

      conversation.agentsSuscribed?.some( (id: string) => {
        if(id === this.agentInfo.id) {
          this.isAssigned = true;
          this.selectedConversation.isAssigned = true;
          this.isAssignedConversation.emit(true);
        } 
      })
    })
  }

  toggleEmojiPicker(): void {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event: any): void {
    event.$event.stopPropagation();
    const text = `${this.newMessage}${event.emoji.native}`;
    this.newMessage = text;
  }

  sendMessage(message: string): void {

    if (!this.selectedConversation.messages) {
      this.selectedConversation.messages = [];
    }

    if (this.isNotes) {
      this.messageObjectToSend = {
        from: this.selectedConversation.from!,
        phoneNumberId: this.agentInfo.phoneNumberId,
        messageBody: message,
        agentName: this.agentInfo.name,
        agentId: this.agentInfo.id,
        type: 'internal',
        cardinality: 'out',
        sendStatus: 'pending',
      };
    } else {
      this.messageObjectToSend = {
        from: this.selectedConversation.from!,
        phoneNumberId: this.agentInfo.phoneNumberId,
        messageBody: message,
        agentName: this.agentInfo.name,
        agentId: this.agentInfo.id,
        type: 'whatsapp',
        cardinality: 'out',
        sendStatus: 'pending',
      };
    }
    this.selectedConversation.messages.push(this.messageObjectToSend as InternalMessage);
    this.helperService.newMessageAdded.next(true);
    this.chatService.sendMessage(this.messageObjectToSend).subscribe((res) => { 
      if(res) {
        this.messageObjectToSend.sendStatus = 'success';
        this.helperService.setStopper(true);
      }
      (error: any) => {
        this.messageObjectToSend.sendStatus = 'failed';
        console.error('Error al enviar el mensaje', error);
      }
    });

    this.newMessage = '';
  }

  changeToNotes(isNotes: boolean): void {
    this.isNotes = isNotes;
    this.helperService.setConversationMode(this.selectedConversation.from!, isNotes);
  }

  uploadAudioFile(event: any): void {
    const fileList: FileList = event.target.files;
    const fileData = validateFileType(fileList);
    this.fileType = fileData.type;

    if (fileList.length > 0 && ALLOWED_TYPES.audioTypes.includes(fileData.mimeType) && fileData.size <= MAXIMUM_SIZE.audioLimit) {
            this.file = fileData.file;
            this.fileName = fileData.fileName
    } else {
      this.cleanFiles();
      this.openSuccessDialog(`El tipo ${fileData.mimeType} no es soportado...`);
    }
  }

  uploadImageFile(event: any): void {

    const fileList: FileList = event.target.files;
    const fileData = validateFileType(fileList);
    this.fileType = fileData.type;

    if (fileList.length > 0 && ALLOWED_TYPES.imageTypes.includes(fileData.mimeType) && fileData.size <= MAXIMUM_SIZE.imageLimit ) {
            this.file = fileData.file;
            this.fileName = fileData.fileName
    } else {
      this.cleanFiles();
      this.openSuccessDialog(`El tipo ${fileData.mimeType} no es soportado...`);
    }
  }

  uploadVideoFile(event: any): void {

    const fileList: FileList = event.target.files;
    const fileData = validateFileType(fileList);
    this.fileType = fileData.type;

    if (fileList.length > 0 && ALLOWED_TYPES.videoTypes.includes(fileData.mimeType) && fileData.size <= MAXIMUM_SIZE.videoLimit ) {
            this.file = fileData.file;
            this.fileName = fileData.fileName
    } else {
      this.cleanFiles();
      this.openSuccessDialog(`El tipo ${fileData.mimeType} no es soportado...`);
    }
  }

  uploadDocFile(event: any): void {

    const fileList: FileList = event.target.files;
    const fileData = validateFileType(fileList);
    this.fileType = fileData.type;

    if ( fileList.length > 0 && ALLOWED_TYPES.documentTypes.includes(fileData.mimeType) && fileData.size <= MAXIMUM_SIZE.documentLimit ) {
            this.file = fileData.file;
            this.fileName = fileData.fileName
    } else {
      this.cleanFiles();
      this.openSuccessDialog(`El tipo ${fileData.mimeType} no es soportado...`);
    }
  }

  sendMedia(): void{
    if (this.isNotes) {
      this.messageObjectMedia = {
        fileName: this.fileName,
        phoneNumberId: this.agentInfo.phoneNumberId,
        mediaType: this.fileType,
        from: this.selectedConversation.from!,
        messageBody: this.newMessage,
        type: 'internal',
        agentId: this.agentInfo.id,
        agentName: this.agentInfo.name,
        cardinality: 'out',
        sendStatus: 'pending',
      };
    } else {
      this.messageObjectMedia = {
        fileName: this.selectedConversation.from + '-' + this.fileName,
        phoneNumberId: this.agentInfo.phoneNumberId,
        mediaType: this.fileType,
        from: this.selectedConversation.from!,
        messageBody: this.newMessage.length > 0 ? this.newMessage : '',
        type: 'whatsapp',
        agentId: this.agentInfo.id,
        agentName: this.agentInfo.name,
        cardinality: 'out',
        sendStatus: 'pending',
      };
    }

    if (!this.selectedConversation.messages) {
      this.selectedConversation.messages = [];
    }

    this.selectedConversation.messages.push(this.messageObjectMedia as InternalMessage);
    this.helperService.newMessageAdded.next(true);

  this.chatService.getPresignedURL(this.messageObjectMedia.fileName)
    .pipe(
      switchMap((response: any) => this.chatService.updateFileInPresignedURL(response.urlPresigned, this.file)),
      switchMap(() => this.chatService.sendMedia(this.messageObjectMedia)),
      catchError(error => {
        console.error('Error al enviar el mensaje', error);
        this.messageObjectMedia.sendStatus = 'failed';
        return of(null);
      })
    )
    .subscribe(res => {
      if (res) {
        this.messageObjectMedia.sendStatus = 'success'; 
        this.helperService.setStopper(true);
      }
    });

  this.newMessage = '';
  this.cleanFiles();
  }

  assignMe(): void {
    const dialogRef = this.dialog.open(ActionConfirmComponent, {
      height: '300px',
      width: '550px',
      data: {
        title: QUESTION_TEXT.join, 
        text: CONFIRM_TEXT.join,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.chatService.assignConversation(this.selectedConversation.phoneNumberId!, this.selectedConversation.from!, this.agentInfo.id).subscribe( res => {
          if(res){
            this.selectedConversation.isAssigned = true;
            this.isAssignedConversation.emit(true);
          }
        });
      }
    });
    
  }

  showMentionList() {
    this.mentionListVisible = true;
  }

  hideMentionList() {
    this.mentionListVisible = false;
  }

  filterMentionItems(filterText: string) {
    this.filteredMentionItems = this.mentionItems.filter(
      mentionItem =>
        mentionItem.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );
  }

  cleanFiles(): void {
    this.documentInput.nativeElement.value = '';
    this.imageInput.nativeElement.value = '';
    this.audioInput.nativeElement.value = '';
    this.videooInput.nativeElement.value = '';
    this.fileName = '';
    this.fileType = '';
  }

  openSuccessDialog(message: string): void {
    this.dialog.open(ActionSuccessComponent, {
      height: '250px',
      width: '500px',
      data: {
        message: message,
      }
    });
  }
}
