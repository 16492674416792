
<div class="container">
<div mat-dialog-content class="no-config-message">
  <p>Contacta a soporte para configurar el módulo</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onClick()">Aceptar</button>
</div>
</div>

